import React from 'react'

import logo from '../img/white-logo.png'
import facebook from '../img/social-icons/facebook.png'
import instagram from '../img/social-icons/instagram.png'
import twitter from '../img/social-icons/twitter.png'
import linkedin from '../img/social-icons/linkedin.png'

const Footer = class extends React.Component {
    render() {
        return (
            <footer className="footer">
                <div className="container footer-inner">
                    <div>
                        <img
                            src={logo}
                            alt="Logo"
                            style={{
                            width: '10rem',
                            height: 'auto'
                        }}/>
                    </div>

                    <div>
                        <a href="">
                            <img className="social-icon" src={facebook} alt="facebook"/>
                        </a>
                        <a href="">
                            <img className="social-icon" src={instagram} alt="instagram"/>
                        </a>
                        <a href="">
                            <img className="social-icon" src={twitter} alt="Twitter"/>
                        </a>
                        <a href="">
                            <img className="social-icon" src={linkedin} alt="Linkedin"/>
                        </a>
                    </div>
                </div>
                <div className="container">
                    <br/>
                    <p className="footer-text text-light font-weight-bold">Privacy Policy | Legal Documents</p>
                    <br/>
                    <p className="footer-text text-light">Disclaimer: Any advice or information on this website is general advice only. It does not take into account your personal circumstances. Please do not trade or invest based solely on this information. By viewing any material or using the information within this site you agree that this is general education material and you will not hold any person or entity responsible for loss or damages resulting from the content or general advice provided here by Marhsall Gittler and its employees, directors or fellow members. 
                        <br/>
                        Please note that forex trading and trading in other leveraged products involves a significant level of risk and is not suitable for all investors.
                        <br/>
                        This website is neither a solicitation nor an offer to buy/sell futures, spot forex, CFDs, options or other financial products.</p>
                </div>
            </footer>
        )
    }
}

export default Footer
