import React from 'react'
import {Link} from 'gatsby'
import logo from '../img/logo-white.png'

const Navbar = class extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            active: false,
            navBarActiveClass: ''
        }
    }

    toggleHamburger = () => {
        // toggle the active boolean in the state
        this.setState({
            active: !this.state.active
        },
        // after state has been updated,
        () => {
            // set the class in state for the navbar accordingly
            this.state.active
                ? this.setState({navBarActiveClass: 'is-active'})
                : this.setState({navBarActiveClass: ''})
        })
    }

    render() {
        return (
            <nav
                className="navbar is-transparent"
                role="navigation"
                aria-label="main-navigation">
                <div className="container">
                    <div className="navbar-brand">
                        <Link to="/" className="navbar-item" title="Logo">
                            <img
                                src={logo}
                                alt="Kaldi"
                                style={{
                                width: 'auto',
                                height: 'auto'
                            }}/>
                        </Link>
                        {/* Hamburger menu */}
                        <div
                            className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                            data-target="navMenu"
                            onClick={() => this.toggleHamburger()}>
                            <span/>
                            <span/>
                            <span/>
                        </div>
                    </div>
                    <div id="navMenu" className={`navbar-menu ${this.state.navBarActiveClass}`}>
                        <div className="navbar-end has-text-centered">
                            <Link className="navbar-item" to="#home">
                                HOME
                            </Link>
                            <Link className="navbar-item" to="#about">
                                ABOUT US
                            </Link>
                            <Link className="navbar-item" to="#service">
                                TRADING
                            </Link>
                            <Link className="navbar-item" to="#membership">
                                MEMBERSHIP
                            </Link>
                            {/* <Link className="navbar-item" to="#blog">
                                BLOG
                            </Link> */}
                            <Link className="navbar-item" to="#contact">
                                CONTACT US
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Navbar
